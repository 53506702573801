<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">
          {{ cardTitle }}
        </h2>

        <span class="card__text">
          {{ cardText }}
        </span>

        <TCard
          :class="[{ 'PD2-106': experiment && experiment.isTreatment }]"
          class="header__card"
        >
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          {{ cardSubtext }}
        </span>
      </div>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import HomeBlock from 'chimera/all/components/HomeBlock'
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep.vue'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Alle goede schilders op één website!',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top bedrijven uit jouw regio. ✓Dé beste prijs voor jouw schilder ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/',
      usps: [
        {
          title: 'Beantwoord een paar vragen',
          text: 'Je vertelt ons wat je zoekt, hierdoor kunnen wij de juiste specialist uit jouw regio aan jou koppelen.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg')
        },
        {
          title: 'Ontvang gratis offertes',
          text: 'Je ontvangt maximaal zes offertes van specialisten die aan je behoeften voldoen. Hiermee bespaar je tot 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg')
        },
        {
          title: 'Kies de juiste specialist',
          text: 'Wij hebben onze specialisten al gecheckt, je hoeft alleen maar de offertes te vergelijken en te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg')
        }
      ],
      cardTitle: 'Vind dé specialist in jouw regio',
      cardText: 'Vergelijk offertes voordat je beslist en bespaar tot 40%!',
      cardSubtext: 'Vergelijk 6 schilders in jouw regio',
      experiment: null
    }
  },

  /**
   * https://bambelo.atlassian.net/browse/PD2-106
   * @returns {Promise<void>}
   */
  async beforeMount () {
    // https://bambelo.absmartly.com/experiments/326
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD2-106',
      source: ExperimentSource.ABSMARTLY
    })
  }
}
</script>

<style lang="scss" scoped>
.page-index .header--white--text .header__card {
  @media (min-width: 541px) {
    @apply max-w-[484px];
  }
}

.page-index .header--white--text .PD2-106.header__card {
  @media (min-width: 541px) {
    @apply max-w-[550px];
  }
}
</style>
